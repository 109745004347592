<template>
    <div class="page">
        <div class="page-header pd">
            <sc-button icon="chevron-left" @click="$router.push(`/app/${resource.route}/index`)">Back</sc-button>
        </div>

        <div class="pd">
            <sc-form :form="form" @submit="submit()" v-if="form">
                <template #field-playlist_items>
                    <sc-form-field :form="form" field="playlist_items" name="Choose Items">

                        <div class="item-options">
                            <sc-button class="option" @click="choose('media')">Choose media items</sc-button>
                            <sc-button class="option" @click="choose('playlist')">Choose Playlist items</sc-button>
                            <sc-button class="option" @click="choose('feed')">Choose Feed items</sc-button>
                            <sc-button class="option" @click="choose('message')">Choose OIS Templates</sc-button>
                            <sc-button class="option" @click="choose('tvfeed')">Choose TV Feed Items</sc-button>
                        </div>

                        <item-select v-if="selectedType" :type="selectedType" @close="closeModal"
                                     @updateSelected="updateSelected"
                                     :selected="form.fields.playlist_items"/>

                    </sc-form-field>

                    <sc-form-field name="Timeline of playlist">
                        <div class="playlist">
                            <div v-if="form.fields.playlist_items.length === 0" style="text-align: center;">
                                <awesome-icon icon="exclamation-triangle" class="icon"/>
                                No items added to timeline yet.
                            </div>

                            <draggable v-else v-model="form.fields.playlist_items" group="playlist-items"
                                       @start="startDrag"
                                       @end="endDrag"
                                       class="scroll-container">

                                <div class="playlist-item" v-for="(item, index) in form.fields.playlist_items"
                                     :key="`playlist-item-${item.name}-${item.sortStamp}-${index}`"
                                     @click="selectItem(index)">

                                    <img :src="imageURL(item)" class="item-img"
                                         v-if="item.type === 'image' || ['playlist','feed','message','tvfeed'].includes(item.playlist_item_type)"/>

                                    <video class="item-img" v-else-if="item.type === 'video'" controls>
                                        <source :src="imageURL(item)" :type="videoType(item)"/>
                                    </video>

                                    <div class="item-img html" v-if="item.type === 'html'">
                                        <awesome-icon :icon="['fab','html5']" class="fa-4x"/>
                                    </div>

                                    <div class="item-description" :data-tooltip="item.name">
                                        <b>{{ item.playlist_item_type }}:</b>
                                        <div class="item-filename">{{ item.name }}</div>
                                    </div>
                                    <awesome-icon v-if="isSelected(index)" icon="check" class="selected"></awesome-icon>
                                    <sc-button @click.stop="scheduleItem = item">Schedule</sc-button>
                                </div>

                            </draggable>

                            <item-schedule v-if="scheduleItem" :item="scheduleItem" @close="scheduleItem = undefined"/>
                        </div>
                    </sc-form-field>

                    <div class="btns" v-if="selected.length > 0">
                        <sc-button @click="removeItems" color="dark" :disabled="form.processing" :running="form.processing">
                            <awesome-icon icon="minus-circle" class="icon"/>
                            Delete playlist item{{ selected.length === 1 ? '' : 's' }}
                        </sc-button>
                    </div>

                </template>
            </sc-form>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import ItemSelect from "@/views/app/playlists/components/item-select";
import ItemSchedule from "@/views/app/playlists/components/item-schedule";

export default {
  name: "new",
  components: {ItemSchedule, draggable, ItemSelect},
  props: {
    passed: {
      required: true,
    }
  },

  data() {
    return {
      form: null,
      selectedType: undefined,
      selected: [],
      scheduleItem: undefined,
      drag: false,
      loading: false
    }
  },

  computed: {
    resource() {
      return this.passed;
    },
  },

  mounted() {
    this.form = this.$sform.createForm({
      name: `New ${this.resource.name}`,
      url: this.resource.api,
      fields: {
        name: {
          name: 'Name*'
        },
        description: {},
        playlist_items: {
          default: []
        }
      },
      success: () => {
        this.$notify.success(`${this.resource.name} added`)
        this.$router.push(`/app/${this.resource.route}/index`)
        this.loading = false;
      },
    })
  },

  methods: {
    submit() {
      this.form.post()
    },

    choose(type) {
      this.selectedType = type;
    },

    updateSelected(changes) {
      // First REMOVE ALL THE THINGS
      // Start at the back,
      for (let i = changes.remove.length - 1; i >= 0; i--) {
        let item = changes.remove[i];
        let index = this.form.fields.playlist_items.findIndex((pi) => {
          return pi.playlist_item_id === item.id && pi.playlist_item_type === this.selectedType;;
        })

        if (index > -1) {
          this.form.fields.playlist_items.splice(index, 1);
        }
      }

      // Second ADD ALL THE THINGS
      for (let i = 0; i < changes.add.length; i++) {
        let item = changes.add[i];
        this.form.fields.playlist_items.push(
          {
            sortStamp: new Date().getTime(),
            playlist_item_id: item.id,
            playlist_item_type: this.selectedType,
            thumb: item.attributes ? item.attributes.thumb : item.thumb,
            src: item.attributes ? item.attributes.src : item.src,
            type: item.attributes ? item.attributes.type : item.type,
            name: item.attributes ? item.attributes.name : item.name,
            duration: 7,
            order: this.form.fields.playlist_items.length,
            schedule: {
              start_date: undefined,
              end_date: undefined,
              times: []
            }
          })
      }

      // Update order for ALL items
      this.ensureOrder();
      this.closeModal()
    },

    selectItem(index) {
      if (!this.isSelected(index)) {
        this.selected.push(index);
      } else {
        let pos = this.selected.indexOf(index);
        this.selected.splice(pos, 1);
      }
    },

    isSelected(index) {
      return this.selected.includes(index);
    },

    removeItems() {
      let sorted = this.selected.sort().reverse();
      sorted.forEach((index) => {
        this.form.fields.playlist_items.splice(index, 1);
      })
      this.selected = [];
      this.scheduleItem = undefined;
    },

    closeModal() {
      this.selectedType = undefined;
    },

    startDrag() {
      this.drag = true;
    },

    endDrag() {
      this.drag = false;
      this.ensureOrder();
    },

    ensureOrder() {
      for (let i = 0; i < this.form.fields.playlist_items.length; i++) {
        this.form.fields.playlist_items[i].order = i;
      }
    },

    imageURL(item) {
      let path;
      if (item.type === 'video') {
        path = item.src;
      } else {
        path = item.thumb
      }
      return process.env.VUE_APP_AWS_URL + path;
    },

    videoType(item) {
      let name = item.src.split('.');
      let ext = name[name.length - 1];
      return `video/${ext}`
    },
  }
}

</script>

<style scoped lang="scss">

.item-options {
    display: flex;

    .option {
        margin-right: .5em !important;
    }
}

.playlist {
    border: 1px solid #e2e2e2;
    padding: 1em 0;
    margin-bottom: 1em;
    overflow-x: auto;
    overflow-y: hidden;

    .scroll-container {
        display: flex;
        height: inherit;

        .playlist-item {
            max-width: 240px;
            min-width: 200px;
            overflow: hidden;
            margin: 0 1em;
            display: flex;
            flex-flow: column;
            align-items: center;
            cursor: pointer;
            position: relative;

            .selected {
                position: absolute;
                width: 5em;
                height: 5em;
                color: #adff2f;
                opacity: .7;
                top: 2em;
            }

            .item-img {
                height: 150px;
                width: 150px;
                box-sizing: border-box;
                object-fit: cover;
                box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.25);
                margin: .5em;

                &.html {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            video.item-img {
                object-fit: contain;
            }

            .item-description {
                width: 90%;
                font-size: .75em;
                margin: .5em auto .75em;
                display: flex;
                justify-content: center;
                align-items: center;

                b {
                    font-weight: bold;
                }

                .item-filename {
                    margin-left: 5px;
                    max-width: 90%;
                    display: inline-block;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

            }
        }
    }
}

.btns {
    width: fit-content;
    margin: 0 auto;
}

</style>